.sliderBg {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.sliderBgOne {
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.35),
      rgba(0, 0, 0, 0.35)
    ),
    url("../../assets/images/Home-page-slider-1.png");
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}

.sliderBgTwo {
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.35),
      rgba(0, 0, 0, 0.35)
    ),
    url("../../assets/images/631049769721a70b67b3dc3d_Home-page-slider-2.jpg");
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}
/* 
.sliderBgThree {
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.35),
      rgba(0, 0, 0, 0.35)
    ),
    url("../../assets/images/CSE-visit.jpg");
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
} */

.sliderBgThree {
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.35),
      rgba(0, 0, 0, 0.35)
    ),
    url("../../assets/images/wifi.jpg");
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}

.sliderBgFour {
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.35),
      rgba(0, 0, 0, 0.35)
    ),
    url("../../assets/images/lkc_35-1.png");
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}

.sliderBgFive {
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.35),
      rgba(0, 0, 0, 0.35)
    ),
    url("../../assets/images/slider_vardmaan.jpg");
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}
/* }
.sliderBgSix {
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.35),
      rgba(0, 0, 0, 0.35)
    ),
    url("../../assets/images/homepageBackgroud1.jpeg");
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
} */

.sliderBgSix {
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.35),
      rgba(0, 0, 0, 0.35)
    ),
    url("../../assets/images/lkc_34.jpg");
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}

/* .sliderBgSeven {
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.35),
      rgba(0, 0, 0, 0.35)
    ),
    url("../../assets/images/homepageBackgroud2.jpeg");
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
} */

.sliderBgSeven {
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.35),
      rgba(0, 0, 0, 0.35)
    ),
    url("../../assets/images/home_page_bg_3.jpg");
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}
