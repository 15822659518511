@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400&display=swap");

.fontStyle {
  font-family: "Roboto Slab", serif;
}
.gr {
  background-color: rgb(192, 189, 189) !important;
}
.scroll {
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

.scroll::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.paginationBtn {
  width: 80%;
  height: 40px;
  list-style: none;
  display: flex;
  justify-content: center;
  margin-top: 25px;
}
.paginationBtn a {
  padding: 10px;
  margin: 8px;
  border-radius: 5px;
  border: 1px solid #01305a;
  color: #01305a;
  cursor: pointer;
}
.paginationBtn a:hover {
  color: white;
  background-color: #01305a;
}
.paginationActive a {
  color: white;
  background-color: #01305a;
}
.nextBtn a {
  border-radius: 5px !important;
}
._btn {
  width: 150px;
  margin-left: 25px;
  margin-top: 10px;
  height: 50px;
  border-radius: 50px;
}

@media only screen and (max-device-width: 480px) {
  .paginationBtn {
    width: 10%;
    height: 10px;
    list-style: none;
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  .paginationBtn a {
    padding: 5px;
    margin: 2px;
    border-radius: 5px;
    border: 1px solid #01305a;
    color: #01305a;
    cursor: pointer;
  }
  .paginationBtn a:hover {
    color: white;
    background-color: #01305a;
  }
  .paginationActive a {
    color: white;
    background-color: #01305a;
  }
  .nextBtn a {
    border-radius: 5px !important;
  }
  ._btn {
    width: 10px;
    margin-left: 2px;
    margin-top: 10px;
    height: 25px;
    border-radius: 50px;
  }
}
