.marquee-container {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  width: 100%;
  height: auto;
}

.marquee3 {
  display: inline-block;
  white-space: nowrap;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
