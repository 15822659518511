@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --toastify-color-success: #32727a !important;
}

.primary-color {
  color: #081c3a;
}

.primary-bg-color {
  background-color: #081c3a;
}

.secondary-color {
  color: #32727a;
}

.secondary-bg-color {
  background-color: #32727a;
}

.bannerImg {
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.55),
      rgba(0, 0, 0, 0.55)
    ),
    /* url('../../assets/images/breadcrumb-p-2000.png'); */
      url("./assets/images/breadcrumb-p-2000.png");
  background-position: center;
  background-size: cover;
}

.bannerBotttom {
  background: rgb(8, 28, 58);
  /* updated height */
  height: 3, 5rem;
  background: linear-gradient(
    160deg,
    rgba(8, 28, 58, 1) 45%,
    rgba(50, 114, 122, 1) 100%
  );
}

.hideScroller::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

.faculty {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 4% 0%;
}

.our-team {
  background: white;
  text-align: center;
  overflow: hidden;
  position: relative;
  margin: 0px 25px;
  padding: 30px 70px;
  height: 350px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}

.our-team .pic {
  display: inline-block;
  width: 130px;
  height: 130px;
  margin-bottom: 0px;
  z-index: 1;
  position: relative;
}

.our-team .pic::before {
  content: "";
  width: 100%;
  height: 0;
  border-radius: 50%;
  background: orange;
  position: absolute;
  bottom: 135%;
  right: 0;
  left: 0;
  opacity: 0.2;
  transform: scale(3);
  transition: all 0.3s linear 0s;
}

.our-team:hover .pic::before {
  height: 100%;
}

.our-team .pic::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: orange;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.our-team .pic img {
  width: 100%;
  height: auto;
  border-radius: 50%;
  transform: scale(1);
  transition: all 0.9s ease 0s;
}

.our-team:hover .pic img {
  box-shadow: 0 0 0 14px white;
  transform: scale(0.7);
}

.our-team .team-content {
  margin-bottom: 30px;
}

.our-team .title {
  font-size: 17px;
  font-weight: 700;
  color: black;
  letter-spacing: 1px;
  margin-bottom: 5px;
}

.our-team .post {
  display: block;
  font-size: 15px;
  color: black;
}

.our-team .details {
  width: 100%;
  padding: 0px;
  margin: 0;
  background: orange;
  position: absolute;
  bottom: -135px;
  left: 0;
  transition: all 0.5s ease 0s;
  color: white;
  font-size: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.details p {
  font-size: 15px;
}

.our-team:hover .details {
  bottom: 0;
}

@media (max-width: 920px) {
  .faculty {
    flex-direction: column;
  }

  .our-team {
    margin-top: 30px;
  }
}

/* --------------About LKCTC images------------------ */
.bgGroup {
  background-image: url("./assets/images/about-1.1-p-500.png");
  background-repeat: no-repeat;
  margin-right: 0px;
  background-position: 50% 50%;
  background-size: cover;
}

.bgFront {
  background-image: url("./assets/images/DSC00214.JPG");
  background-repeat: no-repeat;
  margin-right: 0px;
  background-position: 50% 50%;
  background-size: cover;
}

li::marker {
  font-size: 14px;
}

.Recruiters {
  will-change: transform;
  transform: translate3d(-33.037%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
}

/* ---------Marquee animation- start here--------- */
.orb {
  width: 50px;
  height: 50px;
  background: #999;
  border-radius: 50px;
  display: inline-block;
  transition: all 0.2s ease-out;
}

.marquee {
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  display: flex;
}

.marquee--inner {
  display: flex;
  width: 200%;
  /* margin: 70px 0; */

  position: absolute;
  animation: marquee 13s linear infinite;
}

@keyframes marquee {
  0% {
    left: 0;
  }

  100% {
    left: -100%;
  }
}

/* --------Marquee animation- end here--------- */

/* __________________Swiper Style Starts_______________________ */
/* ____Brand Ambassadors___ */
.brand-ambassadors .swiper {
  width: 100%;
  height: 100%;
}

.brand-ambassadors .swiper-slide {
  position: relative;
  display: flex;
  height: auto;
  width: 100px;
  padding-top: 20px;
  padding-bottom: 20px;
  flex-direction: column;
  margin-right: 10px;
  border-radius: 20px;
  background-color: #081c3a;
  box-shadow: 0 0 20px 0 hsla(0, 0%, 92.7%, 0.35);
}

.brand-ambassadors .swiper-wrapper {
  padding-top: 50px;
}

.brand-ambassadors .swiper-button-next {
  background-color: #32727a;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  top: 48px;
  right: 0;
}

.brand-ambassadors .swiper-button-prev {
  background-color: #32727a;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  top: 48px;
  left: 0;
}

.brand-ambassadors .swiper-button-next:after {
  color: white;
  font-size: 24px;
  font-weight: 600;
  margin-left: 6px;
}

.brand-ambassadors .swiper-button-prev:after {
  color: white;
  font-size: 24px;
  font-weight: 600;
  margin-left: -6px;
}

/* ____Alumni Testimonials___ */
.alumni-testimonials .swiper {
  width: 100%;
  height: 100%;
}

.alumni-testimonials .swiper-slide {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.alumniImg {
  background-image: linear-gradient(
      180deg,
      rgba(8, 28, 58, 0.94),
      rgba(8, 28, 58, 0.94)
    ),
    url("./assets/images/convocation.jpg");
  background-position: center;
  background-size: auto, cover;
}

.btechImg {
  background-image: linear-gradient(
      180deg,
      hsla(0, 0%, 100%, 0.93),
      hsla(0, 0%, 100%, 0.93)
    ),
    url("./assets/images/B-tech-ECE.png");
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}

.alumni-testimonials_2 .swiper {
  width: 100%;
  height: 100%;
}

.alumni-testimonials_2 .swiper-slide {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
}

/* ____Gallery Image PopUp___ */
.gallery-popup .swiper {
  width: 100%;
  height: 100%;
}

.gallery-popup .swiper-slide {
  text-align: center;
  font-size: 18px;
  background-color: rgba(0, 0, 0, 0.2);

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.gallery-popup .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallery-popup .swiper {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.gallery-popup .swiper-slide {
  background-size: cover;
  background-position: center;
}

.gallery-popup .mySwiper2 {
  height: 80%;
  width: 100%;
}

.gallery-popup .mySwiper {
  height: 100%;
  box-sizing: border-box;
  padding: 10px 0;
}

.gallery-popup .mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}
.hightClass {
  height: 720px !important;
}
.gallery-popup .mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.gallery-popup .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* ___Programs___ */
.programs .swiper-button-next {
  background-color: #32727a;
  border-radius: 50%;
  height: 42px;
  width: 42px;
  top: 220px;
  right: 0;
}

.programs .swiper-button-prev {
  position: absolute;
  background-color: #32727a;
  border-radius: 50%;
  height: 42px;
  width: 42px;
  top: 220px;
  left: 0;
}

.programs .swiper-button-next:after {
  color: white;
  font-size: 23px;
  font-weight: 600;
  margin-left: 6px;
}

.programs .swiper-button-prev:after {
  color: white;
  font-size: 23px;
  font-weight: 600;
  margin-left: -6px;
}

/* _____Home Page____ */
.home .swiper {
  width: 100%;
  height: 100%;
}

.home .swiper-button-next {
  /* background-color: #32727a; */
  border-radius: 50%;
  height: 45px;
  z-index: 999;
  width: 45px;
  right: 0;
  background-image: none;
  background-color: hsla(0, 0%, 100%, 0.18);
}

.home .swiper-button-prev {
  /* background-color: #32727a; */
  border-radius: 50%;
  height: 45px;
  z-index: 999;
  width: 45px;
  left: 0;
  background-image: none;
  background-color: hsla(0, 0%, 100%, 0.18);
}

.home .swiper-button-next:after {
  color: white;
  font-size: 20px;
  font-weight: 600;
  margin-left: 6px;
  z-index: 999;
}

.home .swiper-button-prev:after {
  color: white;
  font-size: 20px;
  font-weight: 600;
  margin-left: -6px;
}

.diplomaWrapper .swiper-wrapper {
  justify-content: center;
}

/* __________________Swiper Style Ends_______________________ */

.AboutBanner {
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.6)
    ),
    url(./assets/images/About-banner2.png);
  background-position: 0px 0px, 60% 40%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}

.GoverningBanner {
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.6)
    ),
    url(./assets/images/video-bg-1-1.jpg);
  background-position: 50% 0%;
  background-size: cover;
  background-repeat: no-repeat;
}

.VisionBanner {
  background-image: url(./assets/images/bg1-1.jpg);
  background-position: 50% 0%;
  background-size: cover;
  background-repeat: no-repeat;
}

.CoreValueBanner {
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.6)
    ),
    url(./assets/images/Management-breadcrumb.png);
  background-position: 0px 0px, 60% 40%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}

.IndustryBanner {
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.7),
      rgba(0, 0, 0, 0.7)
    ),
    url(./assets/images/industrypartners-breadcrumb.png);
  background-position: 0px 0px, 60% 40%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}

.CarrierBanner {
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.6)
    ),
    url(./assets/images/video-bg-1-1.jpg);
  background-position: 0px 0px, 60% 40%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}

.contact {
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.6)
    ),
    url(./assets/images/video-bg-1-1.jpg);
  background-position: 0px 0px, 60% 40%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}
.affiliationOrder {
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.6)
    ),
    url(./assets/images/AICTE-EOA-and-AO-breadcrumb.png);
  background-position: 0px 0px, 60% 40%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}

.Management {
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.6)
    ),
    url(./assets/images/Management-breadcrumb.png);
  background-position: 0px 0px, 60% 40%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}
.Faq {
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.6)
    ),
    url(./assets/images/FAQs-breadcrumb.png);
  background-position: 0px 0px, 60% 40%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}

.PlacementBanner {
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.6)
    ),
    url(./assets/images/Placement-cell-breadcrumb.png);
  background-position: 0px 0px, 60% 40%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}
.IndustrialVisit {
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
    url(./assets/images/industry-visits-breadcrumb.png);
  background-position: 0px 0px, 60% 40%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}
.StudentPlaced {
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.6)
    ),
    url(./assets/images/Listofstudents-breadcrumb.png);
  background-position: 0px 0px, 60% 40%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}

.NewsGallery {
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.6)
    ),
    url(./assets/images/newsgallery-breadcrumb.png);
  background-position: 0px 0px, 60% 40%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}

.Brand {
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.6)
    ),
    url(./assets/images/rand-ambassadors-breadcrumb.png);
  background-position: 0px 0px, 60% 40%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}

.Test {
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.6)
    ),
    url(./assets/images/testimonial-breadcrumb.png);
  background-position: 0px 0px, 60% 40%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}
.AlumniMeet {
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.7),
      rgba(0, 0, 0, 0.7)
    ),
    url(./assets/images/Alumni-meet-breadcrumb.png);
  background-position: 0px 0px, 60% 40%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}
.FromTheDeskOf {
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.75),
      rgba(0, 0, 0, 0.75)
    ),
    url(./assets/images/Fromthedeskof-breadcrumb.png);
  background-position: 0px 0px, 60% 40%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}

.header {
  position: fixed;
  height: 50px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  transition: 0.3s ease-in;
  overflow: hidden;
}

.header-bg {
  background-color: #081c3a;
}

.prospects {
  padding-top: 60px;
  padding-bottom: 20px;
  background-image: linear-gradient(
      180deg,
      hsla(0, 0%, 100%, 0.9),
      hsla(0, 0%, 100%, 0.9)
    ),
    url(./assets/images/18527587_278856552576362_7708832162640738418_n.jpg);
  background-position: 0px 0px, 50% 0%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}
/* todo */
.ourCourses {
  background-image: radial-gradient(
    circle farthest-corner at 100% 0%,
    rgba(8, 28, 58, 0.8) 31%,
    rgba(50, 114, 122, 0.8)
  );
}

.noticeboard .swiper {
  width: 100%;
  height: 370px;
  margin-left: auto;
  margin-right: auto;
}

.noticeboard .swiper-slide {
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
}

.enquiry {
  background-image: linear-gradient(
      180deg,
      rgba(8, 28, 58, 0.97),
      rgba(8, 28, 58, 0.97)
    ),
    url(./assets/images/1-19.JPG);

  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  box-shadow: 0 0 11px 0 rgb(239 239 247 / 15%);
}
.enquiry2 {
  background-image: linear-gradient(
      180deg,
      rgba(8, 28, 58, 0.85),
      rgba(8, 28, 58, 0.85)
    ),
    url(./assets/images/1-19.JPG);

  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  box-shadow: 0 0 11px 0 rgb(239 239 247 / 15%);
}

.ourProudPlacement .swiper {
  width: 100%;
  height: 100%;
}

.ourProudPlacement .swiper-button-next {
  background-color: #32727a;
  border-radius: 50%;
  height: 42px;
  width: 42px;
  top: 220px;
  right: 0;
}

.ourProudPlacement .swiper-button-prev {
  position: absolute;
  background-color: #32727a;
  border-radius: 50%;
  height: 42px;
  width: 42px;
  top: 220px;
  left: 0;
}

.ourProudPlacement .swiper-button-next:after {
  color: white;
  font-size: 23px;
  font-weight: 600;
  margin-left: 6px;
}

.ourProudPlacement .swiper-button-prev:after {
  color: white;
  font-size: 23px;
  font-weight: 600;
  margin-left: -6px;
}
.imgGradent {
  background-image: radial-gradient(
    circle farthest-corner at 100% 0%,
    #081c3a 31%,
    rgba(50, 114, 122, 0.8)
  );
  /* opacity: 0.8; */
}
.bgImage {
  background-image: linear-gradient(
      180deg,
      rgba(231, 231, 231, 0.1),
      rgba(231, 231, 231, 0.1)
    ),
    url(./assets/images/bgImg.jpg);
  background-size: cover;
  background-repeat: repeat;
}

section::-webkit-scrollbar {
  display: none;
}

/* ---------Pop-up Swiper Styles ------------- */

.mySwiperPopup .swiper {
  width: 100%;
  height: 100%;
}

.mySwiperPopup .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mySwiperPopup .swiper-slide img {
  display: block;
  width: 100%;
  height: 300px;
  object-fit: contain;
}

@media (min-width: 768px) {
  .mySwiperPopup .swiper-slide img {
    height: 400px;
  }
}

.mySwiperPopup .swiper-button-next:after,
.mySwiperPopup .swiper-button-prev:after {
  font-size: 30px;
  font-weight: 700;
}
@media (min-width: 768px) {
  .mySwiperPopup .swiper-button-next:after,
  .mySwiperPopup .swiper-button-prev:after {
    font-size: 40px;
  }
}
.mySwiperPopup .swiper-horizontal > .swiper-pagination-bullets,
.mySwiperPopup .swiper-pagination-bullets.swiper-pagination-horizontal,
.mySwiperPopup .swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 20px;
}

/* ---------Pop-up Modal Styles ------------- */
.modal {
  font-size: 12px;
}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.modal > .content {
  width: 100%;
  padding: 10px 5px;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  line-height: 20px;
  right: 10px;
  top: 10px;
  border-radius: 18px;
}
.modal > .close2 {
  cursor: pointer;
  position: absolute;
  background-color: #ade1f9;
  display: block;
  line-height: 20px;
  right: -10px;
  top: -11px;
  border-radius: 18px;
}
.popup-content {
  margin: auto;
  background: rgb(255, 255, 255);
  width: 90%;
  padding: 5px;
}
@media (min-width: 1024px) and (max-width: 1440px) {
  .popup-content {
    width: 50%;
  }
}
@media screen and (min-width: 1440px) {
  .popup-content {
    width: 45%;
  }
}
.popup-arrow {
  color: rgb(255, 255, 255);
}
[role="tooltip"].popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup="tooltip"].popup-overlay {
  background: transparent;
}

.criterion-table {
  margin-bottom: 100px;
}

.criterion-table table a:hover {
  text-decoration: underline;
  cursor: pointer;
  color: blue;
}

/* new changes */
